<template>
    <div class="mt-4 md:mt-6"> 
        <label v-if="label" :for="label" class="block text-xs sm:text-sm font-medium leading-5" :class="theme === 'dark' ? 'text-white' : 'text-gray-900'">{{ label }}</label>
        <div class="mt-1 flex items-center">
            <div class="inline-flex flex-col">
                <div @click.prevent="open_file_input" class="h-20 w-20 rounded-full overflow-hidden bg-gray-100">
                    <img v-if="file_url != ''" :src="file_url" :alt="label" class="object-cover h-full w-full">
                    <template v-else>
                        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </template>
                </div>
                <div @click="showModal" class="text-center mt-1 text-xs md:text-sm text-scorelitorange hover:text-scorelitred cursor-pointer">
                    <i18n-t keypath="inputs.image.crop" />
                </div>
            </div>
            <div class="ml-5 rounded-md shadow-sm">
                <button @click.prevent="open_file_input" type="button" class="py-2 px-3 border border-gray-500 rounded-md text-sm leading-4 font-medium text-gray-500 hover:text-gray-400 focus:outline-none active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                    <i18n-t keypath="inputs.image.pick" />
                </button>
            </div>
            <div class="ml-3 text-xs sm:text-sm" :class="theme === 'dark' ? 'text-white' : 'text-gray-900'">{{ file_name }}</div>
            <input ref="input" type="file" @change="update_state" class="hidden-file-selector">
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600 font-medium">{{ error }}</p>
        </template>
    </div>
     
    <div v-if="cropper && modalVisible">
        <Modal @closed="closeModal">
            <template v-slot:header>
                <div class="flex mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 mr-1 text-scorelit-gray" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.5 2a3.5 3.5 0 101.665 6.58L8.585 10l-1.42 1.42a3.5 3.5 0 101.414 1.414l8.128-8.127a1 1 0 00-1.414-1.414L10 8.586l-1.42-1.42A3.5 3.5 0 005.5 2zM4 5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 9a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" clip-rule="evenodd" />
                        <path d="M12.828 11.414a1 1 0 00-1.414 1.414l3.879 3.88a1 1 0 001.414-1.415l-3.879-3.879z" />
                    </svg>
                    <h2 class="uppercase text-xl font-extrabold">
                        <i18n-t keypath="inputs.image.cropTitle" />
                    </h2>
                </div>
            </template>
            <template v-slot:body> 
                <div v-if="file_name">
                    <cropper
                        class="cropper"
                        :src="file_url"
                        :stencil-props="{
                            aspectRatio: 1, 
                            previewClass: 'preview'                       
                        }" 
                        :stencil-component="stencilComponent"
                        @change="change"
                        @crop="crop"
                        ref="cropper"     
                    />
                </div>
                {{ file_name }}
            </template>
            <template v-slot:footer> 
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                    <button @click="crop(); closeModal();" type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                        <i18n-t keypath="inputs.image.crop" />
                    </button>
                    <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                        <i18n-t keypath="general.cancel" />
                    </button>
                </div>
            </template>   
        </Modal>
    </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Modal from "@/components/modals/Modal";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    name: "ImageInput",
    components: {
		Cropper, 
        Modal,
	},
    
    props: ['value', 'errors', 'label', 'url_value', 'theme', 'cropper'],

    data() {
        return {
            modalVisible: false,
            image: '',
        }
    },

    computed:{
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
        file_name: function(){
            if (this.value){
                return this.value.name;
            } else {
                return $t("inputs.image.noFile");
            }
        },
        file_url: function(){
            if (this.value){
                return window.URL.createObjectURL(this.value);
            } else {
                return this.url_value ?? "";
            }
        }
    },
    methods: {
        open_file_input(){
            this.$refs.input.click();
        },
        update_state(){
            const file_path = this.$refs.input.value;
            if (file_path){
                // eslint-disable-next-line
                const file_name = file_path.replace(/^.*[\\\/]/, '');

                const file = this.$refs.input.files[0];

                this.$emit('update:value', file);
                this.clearErrors();
            } else {
                this.$emit('update:value', null);
            }
        },
        clearErrors(){
            this.$emit('update:errors', []);
        },
        closeModal() {
            this.modalVisible = false;
        },
        showModal() {
            this.modalVisible = true;        
        },
        change({ coordinates, canvas }) {
			console.log(coordinates, canvas)
		},
        crop() {
			const { coordinates, canvas, } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;
			this.file_url = canvas.toDataURL();
            console.log(this.image)
		},
        
    },
    
    // created(){
    //     if (this.value == ''){
    //         this.$emit('update:value', null);
    //     }
    // }
    
    watch: {
        file_url() {
            this.modalVisible = true;
        }
    }
}
</script>

<style>
    .hidden-file-selector {
        position: absolute; 
        left: -99999rem;
    }

    .cropper {
        max-height: 600px;
    }

    .preview{
        background-image: url('~@/assets/images/eyes.png');
        background-repeat: no-repeat;  
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

</style>